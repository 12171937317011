import React from 'react'
import Podcast from '../components/Podcast'
import MainLayout from '../components/MainLayout'

const PodcastPage = () => {
  return (
    <MainLayout>
      <Podcast />
    </MainLayout>
  )
}

export default PodcastPage
