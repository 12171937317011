import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import { ButtonGhostInverse } from '../../components/Button'
import PodcastHero from './PodcastHero'
import { BackgroundSectionStyle } from '../Section/backgroundSection.css.js'
import { Primary, Alternate, Secondary } from '../../components/Section'

import ImgDarnPodcast from '../../images/darn-podcast.jpg'
import { calculateRem } from '../../utils/javascript-styles'

const DarnPodcastLogo = styled.img`
  display: block;
  height: ${ calculateRem(250) };
  margin: 0 auto 2rem auto;
`
const StyledPodcastHero = styled(PodcastHero)`
  ${ BackgroundSectionStyle };
  padding-top: 10rem;
  min-height: ${ props => props.backgroundHeight }px;
  text-align: ${ props => (props.centered ? 'center' : 'initial') };
  h1,
  h2,
  h3 {
    color: ${ props => props.theme.atoll };
  }
`

const Podcast = () => {
  return (
    <section>
      <StyledPodcastHero backgroundHeight={750} centered />
      <Primary centered>
        <Row>
          <Col xs={12}>
            <p>Contact Us to be a part of the podcast</p>
            <ButtonGhostInverse>Listen</ButtonGhostInverse>
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default Podcast
